.tabla{
    font-size: 1.2rem;
    width: 39vw;
    border-collapse: collapse;
}
.contenido div::-webkit-scrollbar {

    width:1vw
}
.contenido div::-webkit-scrollbar-track {
    border-radius: 0.5vw;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}
.Grabaciones .contenido div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #714620;
}
.contenido{
    display:flex;
    flex-direction: row;
    margin-top: 2vw;
}

.tabla th,
td,
tr {
    
    
    border-bottom: 1px solid;
    text-align: center;
}
.ver{
    width: 50vw;
    height: 75vh;
    margin-left: 2vw;
    text-align: center;
    align-content: center;

}
