.Inicio {
  display: flex;
  height: '100%';
  width: '100%';
  flex-direction: column;
  background-image: url('./Img/FondoInicio.png');
  background-size: cover;
  align-items: center;
  justify-content: center;
}



.Practica {
  display: flex;
  height: '100%';
  width: '100%';
  flex-direction: column;
  background-image: url('./Img/FondoPractica.jpg');
  background-size: cover;
}

.Grabaciones {
  display: flex;
  height: '100%';
  width: '100%';
  flex-direction: column;
  background-image: url('./Img/FondoGrabaciones.jpg');
  background-size: cover;
}

.Datos {
  display: flex;
  height: '100%';
  width: '100%';
  flex-direction: column;
  background-image: url('./Img/FondoDatos.jpg');
  background-size: cover;
}

.Boton {
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 5px;
  margin: 2vh;
}

.Boton p {
  height: '100%';
  width: '100%';
  color: white;
  text-align: center;
  font-size: large;
  font-weight: bolder;
}