.opciones {
    display: flex;
    height: '100%';
    width: '100%';
    flex-direction: row;
}
.opciones div{
    padding-top: 1vh;
}

.opciones div:hover{
    scale: 1.1;
    
    cursor:pointer;
}

.opciones div:last-child:hover{
    transform: scale(1.1,1.1);
    transform-origin: right;
}